<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>抄送我的</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="send-info">
            <div class="send-condition flex-center">
                <div class="send-condition-item">
                    <el-select
                            v-model="type"
                            placeholder="请选择审批类型"
                            @change="classifyChange($event, 0)"
                    >
                        <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class=" flex-center">
                    <el-input
                            class="send-condition-item"
                            v-model="form"
                            placeholder="请输入项目发起人"
                    ></el-input>
                    <el-button class="send-condition-item" type="primary" @click="searchList">搜索</el-button>

                </div>
                <div class="send-condition-item">
                    <el-date-picker
                            @change="timeChange"
                            v-model="start_end_time"
                            type="daterange"
                            value-format="timestamp"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </div>
            </div>

            <div class="send-list">
                <el-table
                        :data="tableData"
                        header-row-class-name="table-header"
                        @row-click="toDetail"
                        style="width: 100%"
                >
                    <el-table-column prop="title" label="审批标题" width="240">
                        <template slot-scope="scope">
                            <div class="table-title flex-center">
                <span :class="{dot:scope.row.if_read === 0}">{{
                    scope.row.form_name.substr(
                        scope.row.form_name.length - 2,
                        scope.row.form_name.length
                    )
                    }}</span>
                                <p :class="{ active: scope.row.level === 3 }">
                                    <b>{{ scope.row.form_name }}</b
                                    >提交的<b>{{ scope.row.type | typeFilter }}</b>

                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="审核摘要">
                        <template slot-scope="scope">
                            <div
                                    v-html="scope.row.desc"
                                    class="table-desc"
                                    :class="{ active: scope.row.level === 3 }"
                            ></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="addtime" label="项目发起人" width="180">
                        <template slot-scope="scope">
                            <div
                                    class="table-addtime"
                                    :class="{ active: scope.row.level === 3 }"
                            >
                                {{ scope.row.true_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="addtime" label="发起时间" width="180">
                        <template slot-scope="scope">
                            <div
                                    class="table-addtime"
                                    :class="{ active: scope.row.level === 3 }"
                            >
                                {{ scope.row.add_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endtime" label="完成时间" width="180">
                        <template slot-scope="scope">
                            <div
                                    class="table-endtime"
                                    :class="{ active: scope.row.level === 3 }"
                            >
                                {{ scope.row.sp_time ? scope.row.sp_time : "" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="状态" width="200">
                        <template slot-scope="scope">
                            <div
                                    class="table-state"
                                    :class="{
                  active2: scope.row.level === 2,
                  active3: scope.row.level === 3,
                }"
                            >
                                <p :class="{
                    active: scope.row.state_code === 3,
                    green:
                      scope.row.state_code === 2 && Number(scope.row.type) == 5,
                  }">
                                    {{ scope.row.state }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="page-all"
                        background
                        layout="prev, pager, next"
                        :page-size="limit"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {getApprovalType,toDetail} from "@/tools";

    export default {
        name: "cc",
        data() {
            return {
                //审批类型 0全部 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                typeOptions: this.$tools.approvalClassify(),
                type: "",
                tableData: [],
                page: 1,
                pageTotal: 1,
                limit: 6,
                start_end_time: [],
                form: "",
            };
        },
        activated() {
            this.$emit("setIndex", [0, 3]);
            this.init();
        },
        methods: {
            /*去项目详情*/
            toDetail(row) {
                row.if_read = 1
                let findIndex = this.tableData.findIndex(f=>{ return f.oid === row.oid});
                this.tableData.splice(findIndex,1,row)
                toDetail(row,2)
            },
            /*修改项目状态*/
            setState(id) {
                console.log(id);
            },
            // 分页变化
            setPage(val) {
                this.page = val;
                this.init();
            },
            searchList() {
                this.page = 1
                this.init();
            },
            timeChange() {
                this.page = 1
                this.init();
            },
            classifyChange(event, view) {
                if (view === 0) {
                    this.type = event;
                } else if (view === 1) {
                    this.lvl = event;
                }
                this.page = 1;
                this.init();
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .myCopy({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        add_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? this.start_end_time[0] / 1000
                                : "",
                        end_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? ((this.start_end_time[1] / 1000) + (24 * 60 * 60))
                                : "",
                        name: this.form,
                        type: this.type ? this.type : 0,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                page: this.page,
                                limit: this.limit,
                                add_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? this.start_end_time[0] / 1000
                                        : "",
                                end_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? ((this.start_end_time[1] / 1000) + (24 * 60 * 60))
                                        : "",
                                name: this.form,
                                type: this.type ? this.type : 0,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.list.map((item) => {
                            item.add_time = this.$tools.ZHDate(
                                new Date(item.add_time * 1000),
                                1
                            );
                            item.sp_time = item.sp_time
                                ? this.$tools.ZHDate(new Date(item.sp_time * 1000), 1)
                                : "";
                        });
                        this.tableData = res.data.list;
                        this.pageTotal = res.data.count;
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.pageTotal = 0
                    });
            },
        },
        watch: {
            state() {
                this.page = 1;
                this.init();
            },
        },
        filters: {
            typeFilter(type) {
                // 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                return getApprovalType(type)
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .send-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .send-condition {
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .send-condition-item {
                    margin-right: 20px;
                }

                .send-condition-state {
                    border: 1px solid #3b77e7;
                    border-radius: 4px;
                    overflow: hidden;

                    span {
                        display: block;
                        width: 120px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        font-size: 16px;
                        border-right: 1px solid #3b77e7;
                        cursor: pointer;

                        &:last-child {
                            border-right: 0;
                        }

                        &.active {
                            background: #3b77e7;
                            color: #fff;
                        }
                    }
                }
            }

            .send-list {
                padding: 34px 28px;
                min-height: 600px;

                .table-title {
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 18px;
                        color: #fff;
                        background-color: #3b77e7;
                       &.dot{
                           position: relative;
                           &:after{
                               position: absolute;
                               content: '';
                               width: 12px;
                               height: 12px;
                               border-radius: 12px;
                               top: 0;
                               right: 0;
                               background: red;
                           }
                       }
                    }

                    p {
                        flex: 1;
                        margin-left: 10px;
                        font-size: 16px;
                        color: #000;

                        &.active {
                            color: #f03737;

                            b {
                                color: #f03737;
                            }
                        }

                        &.shenpi {
                            color: lightseagreen;

                            b {
                                color: lightseagreen;
                            }
                        }

                        b {
                            color: #3b77e7;
                            font-weight: normal;
                        }
                    }
                }

                .table-desc {
                    text-align: left;
                    font-size: 16px;
                    color: #666666;
                    padding-left: 20px;

                    &.active {
                        color: #f03737;
                    }
                }

                .table-addtime {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    &.active {
                        color: #f03737;
                    }
                }

                .table-endtime {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    &.active {
                        color: #f03737;
                    }
                }

                .table-state {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    text-align: center;
                    font-size: 16px;
                    position: relative;

                    &.active2 {
                        background: url("../../assets/img/level2.png") no-repeat left center;
                        background-size: auto 100%;
                    }

                    &.active3 {
                        background: url("../../assets/img/level3.png") no-repeat left center;
                        background-size: auto 100%;
                    }

                    p {
                        min-height: 60px;
                        line-height: 60px;
                        color: #3b77e7;

                        &.active {
                            color: red;
                        }
                        &.green {
                            color: green;
                        }

                        .el-dropdown {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 26px;

                            img {
                                display: block;
                                width: 26px;
                                height: 26px;
                            }
                        }
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }
</style>
